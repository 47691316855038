import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { OrthographicCamera, useProgress } from "@react-three/drei";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStepForward,
  faPlay,
  faPause,
  faMicrophone,
  faStop,
  faRepeat,
  faBackwardFast,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import ReactAudioPlayer from "react-audio-player";

import "./App.css";
import Avatar from "./avatar";
import "./utils";

import axios from "axios";
import { useMediaQuery } from 'react-responsive';
import _ from "lodash";
import { IMaskInput } from 'react-imask'

const backendAPI = "https://backend.vm.airdec.net";
const textToVoiceAPI = "https://tts.vm.airdec.net";
const virtualMeAPI = "https://doc.vm.airdec.net";
let bgImage = undefined;
let initialText = null;
const unsupportedLanguageText = `Thank you for your interest! At present, we proudly offer support in English, French, German, Spanish, Italian, Chinese, Japanese, and Korean. If you have any further inquiries or need assistance in other ways, please feel free to call us at 202-802-7761 or email us at info@airdec.net. We're here to help!`;

let emotion = 0;
let initVoice = undefined;
const initFlag =
  '<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-us" viewBox="0 0 640 480"> <path fill="#bd3d44" d="M0 0h640v480H0"/> <path stroke="#fff" stroke-width="37" d="M0 55.3h640M0 129h640M0 203h640M0 277h640M0 351h640M0 425h640"/> <path fill="#192f5d" d="M0 0h364.8v258.5H0"/> <marker id="us-a" markerHeight="30" markerWidth="30"> <path fill="#fff" d="m14 0 9 27L0 10h28L5 27z"/> </marker> <path fill="none" marker-mid="url(#us-a)" d="m0 0 16 11h61 61 61 61 60L47 37h61 61 60 61L16 63h61 61 61 61 60L47 89h61 61 60 61L16 115h61 61 61 61 60L47 141h61 61 60 61L16 166h61 61 61 61 60L47 192h61 61 60 61L16 218h61 61 61 61 60L0 0"/> </svg>';
let voice = initVoice,
  flag = initFlag;

function Page({ backendData }) {
  const audioPlayer = useRef();
  const introductionBtn = useRef();

  const [responseArray, setResponseArray] = useState([]);
  const [responseInterval, setResponseInterval] = useState(-1);

  const [textQueue, setTextQueue] = useState([]);
  const [speakingInterval, setSpeakingInterval] = useState(0);

  const [waitForAzure, setWaitForAzure] = useState(false);
  const [waitResponse, setWaitResponse] = useState(false);

  const [startSpeak, setStartSpeak] = useState(false);
  const [text, setText] = useState("");
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(null);
  const [startOver, setStartOver] = useState(-1);
  const [uuid, setUuid] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [visible, setVisible] = useState(true);
  const [chatArray, setChatArray] = useState([]);

  const [ads, setAds] = useState([]);
  const [settings, setSettings] = useState([]);
  const [waitingTexts, setwaitingTexts] = useState([]);
  const [waitingSpeeches, setWaitingSpeeches] = useState([]);
  const [adsCounter, setAdsCounter] = useState(0);
  const [idleTime, setIdleTime] = useState(0);
  const [introductionText, setIntroductionText] = useState("Introduction");

  const [appSlug, setAppSlug] = useState(backendData.data_slug ?? null);

  const animationSelector = window.location.hostname === "localhost";
  const [animations, setAnimations] = useState(null);
  const [playingAnimation, setPlayingAnimation] = useState(null);

  const [displayForm, setDisplayForm] = useState(undefined);

  const micIcon = <FontAwesomeIcon icon={faMicrophone} />;
  const skipIcon = <FontAwesomeIcon icon={faStepForward} />;
  const sendIcon = <FontAwesomeIcon icon={faPlay} />;
  const repeatIcon = <FontAwesomeIcon icon={faRepeat} />;
  const startOverIcon = <FontAwesomeIcon icon={faBackwardFast} />;
  const pauseIcon = <FontAwesomeIcon icon={faPause} />;
  const stopIcon = <FontAwesomeIcon icon={faStop} />;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  function makeSpeech(text) {
    return axios.post(textToVoiceAPI + "/talk/", {
      text: text,
      voice: voice,
    });
  }

  useEffect(() => {
    const fetchWaitingTexts = async () => {
      const response = await axios.get(backendAPI + "/db/get-waiting-speeches/");
      setwaitingTexts(response.data.data);
    };

    fetchWaitingTexts().catch(console.error);
  }, []);

  useEffect(() => {
    waitingTexts.forEach(waitingText => {
      makeSpeech(waitingText.text)
        .then((response) => {
          setWaitingSpeeches((waitingSpeeches) => [
            ...waitingSpeeches,
            response.data,
          ]);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, [waitingTexts]);

  // TIMER for Ads Loading & Survey Button
  useEffect(() => {
    if (window.location.hostname === "localhost")
      return
    if (!document.hasFocus()) {
      setIdleTime(0);
    }
    if (
      idleTime >=
      parseInt(
        settings?.find((setting) => setting.key === "idleTime")?.value ?? 120
      )
    ) {
      if (introductionText !== "Survey" && backendData.survey_active) {
        setVisible(true);
        setIntroductionText("Survey");
        introductionBtn.current.classList.toggle("btn--wiggle");
      }
      let theAds = ads.filter((ad) => ad.priority === adsCounter);
      if (theAds.length === 0) {
        setAdsCounter(0);
        theAds = ads.filter((ad) => ad.priority === 0);
      } else {
        setAdsCounter(adsCounter + 1);
      }
      const theAd = theAds.sample();
      const initialTextArray = theAd["text"]
        .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
        .split("|");
      setSpeakingInterval(0);
      setTextQueue(initialTextArray);
      setIdleTime(0);
    } else {
      const timer = setInterval(() => setIdleTime(idleTime + 10), 10000);
      return () => clearInterval(timer);
    }
  }, [idleTime]);

  useEffect(() => {
    getAds();
    getSettings();
  }, []);

  const getAds = async () => {
    const response = await axios.get(backendAPI + "/db/get-ads/");
    setAds(response.data.data);
  };

  const getSettings = async () => {
    const response = await axios.get(backendAPI + "/db/get-settings/");
    setSettings(response.data.data);
  };

  function doInitSpeechOrSurvey() {
    if (introductionText.toLowerCase() === "survey" && appSlug) {
      setVisible((prev) => !prev);
      window.open(backendData.survey_url, "_blank");
    } else {
      if (appSlug) {
        setIntroductionText("Survey");
        introductionBtn.current.classList.toggle("btn--wiggle");
      } else {
        setVisible((prev) => !prev);
      }
      setChatArray((oldArray) => [
        `[${new Date().toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}] AI: ` +
        initialText +
        "\n\n",
        ...oldArray,
      ]);
      const initialTextArray = initialText
          .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
          .split("|");
      setTextQueue(initialTextArray);
      setSpeakingInterval(0);
      setDisplayForm(false);
    }
  }

  useEffect(() => {
    setResponseArray([]);
    if (textQueue.length > 0) {
      setResponseInterval(0);
    }
  }, [textQueue]);

  useEffect(() => {
    if (responseInterval > -1 && responseInterval < textQueue.length) {
      setIdleTime(0);
      makeSpeech(textQueue[responseInterval])
        .then((response) => {
          setResponseArray((responseArray) => [
            ...responseArray,
            response.data,
          ]);
          setResponseInterval(responseInterval + 1);
          if (responseInterval === 0) {
            console.log("bayad biad inja dige");
            stopSpeakFunc();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [responseInterval]);

  function ask() {
    setWaitResponse(true);
    setChatArray((oldArray) => [
      `[${new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}] You: ` +
        text +
        "\n\n",
      ...oldArray,
    ]);
    setText("");

    const fetchData = async () => {
      setFetching(true);
      const response = await axios.post(virtualMeAPI + "/chat/", {
        app_slug: appSlug,
        message: text,
        uuid: uuid,
      });

      setSpeakingInterval(0);
      const initialTextArray = response.data.respond.voice
        ? response.data.respond.answer
            .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
            .split("|")
        : unsupportedLanguageText
            .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
            .split("|");
      emotion = response.data.respond?.emotion ?? 0;
      if (!response.data.respond.voice) {
        voice = initVoice;
        flag = initFlag;
      }
      setChatArray((oldArray) => [
        `[${new Date().toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}] AI: ` +
          (response.data.respond.voice
            ? response.data.respond.answer
            : unsupportedLanguageText) +
          "\n\n",
        ...oldArray,
      ]);

      setTextQueue(initialTextArray);

      setFetching(false);
    };
    fetchData().catch(console.error);
  }

  function stopSpeakFunc() {
    setAudioSource(null);
    setPlaying(false);
    setStartSpeak(true);
  }

  function onEnterPress(e) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      stopSpeakFunc();
      setTextQueue([]);
      ask();
    } else {
      setIdleTime(0);
    }
  }

  // End of play
  function playerEnded(e) {
    stopSpeakFunc();
  }

  function repeatSentence() {
    audioPlayer.current.audioEl.current.pause();
    setPlaying(false);
    setStartOver(speakingInterval-1);
  }

  function doStartOver() {
    audioPlayer.current.audioEl.current.pause();
    setPlaying(false);
    setStartOver(0);
  }

  useEffect(() => {
    if (startOver >= 0) {
      setSpeakingInterval(startOver);
      setResponseInterval(0);
      setStartOver(-1);
    }
  },[startOver]);

  function pausePlayAudio() {
    if (playing) {
      audioPlayer.current.audioEl.current.pause();
    } else {
      audioPlayer.current.audioEl.current.play();
    }
    setPaused(playing);
    setPlaying(!playing);
  }

  function skipTalk() {
    audioPlayer.current.audioEl.current.pause();
    setPaused(null);
    setAudioSource(null);
    setTextQueue([]);
    setResponseArray([]);
    setPlaying(false);
  }

  function recordVoice() {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }
  }

  useEffect(() => {
    setText(transcript);
  }, [transcript]);

  // Player is read
  function playerReady(e) {
    // setText("");
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  }

  const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Simple tooltip
      </Tooltip>
  );
  const Link = ({ id, children, title }) => (
      <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-top`}>
              {title}
            </Tooltip>
          }
      >
        {children}
      </OverlayTrigger>
  );

  useEffect(() => {
    if (!appSlug)
      return
    const fetchData = async () => {
      const response = await axios.get(
        virtualMeAPI + "/get-app/" + appSlug + "/"
      );
      setUuid(response.data.app.uuid);
    };

    fetchData().catch(console.error);
  }, []);

  // function textareaInput() {
    // Resize Textarea on Input
    // textbox.current.style.height = 'auto';
    // textbox.current.style.height = `${textbox.current.scrollHeight}px`;
    // Update Idle Counter on Input
  // }

  useEffect(() => {

    const main = document.querySelector("#mainPage");
    const avatar = document.querySelector("#avatarCanvas");

    if (!isTabletOrMobile) {
      main.addEventListener("mousemove", (e) => {
        main.style.backgroundPositionX = -e.pageX / 10 + "px";
        main.style.backgroundPositionY = -e.pageY / 10 + "px";
  
        avatar.style.left = e.pageX / 50 + "px";
        avatar.style.top = e.pageY / 50 + "px";
      });
    }
    
  }, []);

  function LoadingScreen() {
    const { progress } = useProgress();
    return (
      progress < 100 && (
        <div className="loading-container">
          <div className="loading-box">
            <div className="loading-bar">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              {/* <div className="loading-percentage">{progress.toFixed(0)}%</div> */}
            </div>
            <div className="loading-text">
              The wait is almost over! We're so excited for you to experience
              the app's full functionality. We're working hard in the background
              so you can get the most out of our application...
            </div>
          </div>
        </div>
      )
    );
  }

  function changeAnimation(state) {
    setPlayingAnimation(state.value);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    document.getElementById('formSubmitButton').disabled = true;
    let formFields = {}
    backendData.forms[0].form_fields.forEach(function (form_field) {
      formFields['field-' + form_field.id] = document.getElementById('field-' + form_field.id).value
    });
    let data = {
      website: window.location.hostname,
      formId: backendData.forms[0].id,
      fields: formFields
    }
    const response = await axios.post(backendAPI + "/db/submit-forms/", data);
    if (response.status === 200) {
      document.getElementById('formSubmitButton').innerText = 'Sent';
      setTimeout(() => {
        document.getElementById('centerContainer').classList.remove('opacity-100')
        document.getElementById('centerContainer').classList.add('opacity-0');
      }, 2000)
    } else {
      alert('Something went wrong!');
      document.getElementById('formSubmitButton').disabled = false;
    }

  }

  return (
    <div
      id="mainPage"
      className="main"
      style={{ backgroundImage: `url('${backendAPI}${bgImage}')` }}
    >

      {/* <div className="display-box top-right-display position-absolute">
        <iframe src="https://www.youtube.com/embed/E0Jiu-1cx40?si=chm5uRyHxtWPigDW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <img src="https://c4.wallpaperflare.com/wallpaper/433/352/1021/mortal-kombat-goro-1024x768-video-games-mortal-kombat-hd-art-wallpaper-preview.jpg" />
        <video controls>
          <source src="realstatevideo.mp4" type="video/mp4" />
        </video> 
      </div> */}


      <div className="leftBar p-2">
        <div className="chat-history-parent">
          {(backendData.introduction_contact) &&
              <div className={`transition-1s ${displayForm ? "opacity-100 visible" : "opacity-0 hidden"}`}>
                <div className="bg-dark p-4 rounded-3 bg-opacity-75">
                  <h2 className="text-light mb-3">Signup Form</h2>
                  <form className="" onSubmit={handleFormSubmit}>
                    {backendData.forms[0] && backendData.forms[0].form_fields.map(function(object, i) {
                      if (object.type === 'tel')
                        return <IMaskInput
                            mask="+{1} (000)-000-0000"
                            unmask={false}
                            className="form-control my-3" key={i} type='tel' id={"field-" + object.id} name={object.name} placeholder={object.name} maxLength={object.length} required
                        />
                      return <input className="form-control my-3" key={i} type={object.type} id={"field-" + object.id} name={object.name} placeholder={object.name} maxLength={object.length} required />;
                    })}
                    <button type={"submit"} className="btn btn-success" id="formSubmitButton">Submit</button>
                  </form>
                </div>
              </div>
          }
          <div className="chat-history">{chatArray}</div>
        </div>

        <div className="d-flex flex-column gap-2">
          {appSlug && (
          <div className="position-relative">
                <textarea
                    className="form-control chatbox"
                    placeholder="Type your Question/Response here..."
                    rows={5}
                    maxLength={500}
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    disabled={fetching}
                    onKeyDown={(e) => onEnterPress(e)}
                />
                <div
                id="flag"
                className="position-absolute"
                dangerouslySetInnerHTML={{ __html: flag }}
            ></div>

          </div>
          )}
          <div>
            <div className="d-flex gap-2 mt-2 justify-content-between">
              {appSlug && (<Link title="Send/Respond">
                <button
                  type="button"
                  className="btn btn-primary rounded-pill"
                  style={{ width: "7rem" }}
                  onClick={() => ask()}
                  disabled={playing || fetching || !text}
                >
                  {sendIcon}
                </button>
              </Link>)}
              <Link title="Pause Talk">
                <button
                  type="button"
                  className="btn btn-primary rounded-pill"
                  style={{ width: "4rem" }}
                  onClick={() => pausePlayAudio()}
                  disabled={!playing && !audioSource}
                >
                  {playing ? pauseIcon : sendIcon}
                </button>
              </Link>
              {!appSlug && (<Link title="Repeat Sentence">
                <button
                    type="button"
                    className="btn btn-primary rounded-pill"
                    style={{ width: "7rem" }}
                    onClick={() => repeatSentence()}
                    disabled={!playing && !audioSource}
                >
                  {repeatIcon}
                </button>
              </Link>)}
              {!appSlug && (<Link title="Start Over">
                <button
                    type="button"
                    className="btn btn-primary rounded-pill"
                    style={{ width: "7rem" }}
                    onClick={() => doStartOver()}
                    disabled={!playing && !audioSource}
                >
                  {startOverIcon}
                </button>
              </Link>)}
              <Link title="End Talk">
                <button
                  type="button"
                  className="btn btn-secondary rounded-pill"
                  style={{ width: "4rem" }}
                  onClick={() => skipTalk()}
                  disabled={!playing}
                >
                  {skipIcon}
                </button>
              </Link>
              {browserSupportsSpeechRecognition && (
                <Link title="Talk">
                  <button
                    type="button"
                    className="btn btn-secondary rounded-pill"
                    style={{ width: "4rem" }}
                    onClick={() => recordVoice()}
                  >
                    {listening ? stopIcon : micIcon}
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={visible ? "intro-container" : "hidden intro-container"}>
        <button
          onClick={() => doInitSpeechOrSurvey()}
          ref={introductionBtn}
          className="btn btn-primary rounded-pill px-5 py-3 fw-bold intro-button"
        >
          {introductionText}
        </button>
      </div>
      
      
        <div className="logo-container">
        {backendData.logo && 
          <a
          target="_blank"
          href={backendData.classic_web_active && backendData.classic_web_url}
          className=""
          title={backendData.classic_web_active && "Classic View"}
        >
          <img src={backendAPI + backendData.logo} className="logo" />
          </a>
        }
          
          {/* {backendData.classic_web_active && (
            <div className="classic-container">
              <a
                target="_blank"
                href={backendData.classic_web_url}
                className="btn btn-dark rounded-pill"
              >
                Classic View
              </a>
            </div>
          )} */}
        </div>

        <div className="bottom-right-container">
          {animationSelector && <div>
            <select name="animations" id="animations" onChange={(e) => changeAnimation(e.target)}>
              {animations && animations.map(function(object, i){
                return <option key={i} value={i}>{object.name}</option>;
              })}
            </select>
          </div>}
          {settings.find(setting => setting.key === 'copyrightBanner') && <div className="d-flex justify-content-center align-items-center">
            <div
              className="text-white copyright"
              dangerouslySetInnerHTML={{ __html: settings.find(setting => setting.key === 'copyrightBanner')?.value }}
            ></div>
          </div>}
        </div>

      {/*{(backendData.introduction_contact) &&*/}
      {/*    <div id="centerContainer" className={`center-container transition-1s ${displayForm ? "opacity-100 visible" : "opacity-0 hidden"}`}>*/}
      {/*      <div className="bg-dark p-4 rounded-3 bg-opacity-75">*/}
      {/*        <h2 className="text-light mb-3">Signup Form</h2>*/}
      {/*        <form className="" onSubmit={handleFormSubmit}>*/}
      {/*          {backendData.forms[0] && backendData.forms[0].form_fields.map(function(object, i) {*/}
      {/*            if (object.type === 'tel')*/}
      {/*              return <IMaskInput*/}
      {/*                        mask="+{1} (000)-000-0000"*/}
      {/*                        unmask={false}*/}
      {/*                        className="form-control my-3" key={i} type='tel' id={"field-" + object.id} name={object.name} placeholder={object.name} maxLength={object.length} required*/}
      {/*                    />*/}
      {/*            return <input className="form-control my-3" key={i} type={object.type} id={"field-" + object.id} name={object.name} placeholder={object.name} maxLength={object.length} required />;*/}
      {/*          })}*/}
      {/*          <button type={"submit"} className="btn btn-success" id="formSubmitButton">Submit</button>*/}
      {/*        </form>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*}*/}
      
      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlay={playerReady}
      />

      <Canvas
        id="avatarCanvas"
        dpr={0.75}
        // onCreated={(ctx) => {
        //   ctx.gl.physicallyCorrectLights = true;
        // }}
      >
        {/* <ambientLight /> */}
        <pointLight position={JSON.parse(backendData.first_light_position)} intensity={backendData.first_light_tensity} />
        <pointLight position={JSON.parse(backendData.second_light_position)} intensity={backendData.second_light_tensity} />
        <pointLight position={JSON.parse(backendData.third_light_position)} intensity={backendData.third_light_tensity} />

        <OrthographicCamera
          makeDefault
          zoom={backendData.camera_zoom}
          position={JSON.parse(backendData.model_position)}
        />

        <Suspense fallback={null}>
          <Avatar
            backendAPI={backendAPI}
            textToVoiceAPI={textToVoiceAPI}
            avatar_url={backendAPI + backendData.model}
            // avatar_url='test.glb'
            backendData={backendData}
            setAudioSource={setAudioSource}
            playing={playing}
            paused={paused}
            setPaused={setPaused}
            queueInterval={textQueue.length}
            speakingInterval={speakingInterval}
            setSpeakingInterval={setSpeakingInterval}
            responseArray={responseArray}
            startSpeak={startSpeak}
            setStartSpeak={setStartSpeak}
            waitForAzure={waitForAzure}
            setWaitForAzure={setWaitForAzure}
            waitResponse={waitResponse}
            setWaitResponse={setWaitResponse}
            emotion={emotion}
            waitingSpeeches={waitingSpeeches}
            animations={animations}
            setAnimations={setAnimations}
            playingAnimation={playingAnimation}
            setPlayingAnimation={setPlayingAnimation}
            setDisplayForm={setDisplayForm}
          />
        </Suspense>
      </Canvas>
      <LoadingScreen />
    </div>
  );
}

function App() {
  const [backendData, setBackendData] = useState(null);

  useEffect(() => {
    getVM();
  }, []);

  const getVM = async () => {
    const response = await axios.get(backendAPI + "/db/get-vm/?app_url=" + (window.location.hostname === "localhost" ? "test" : window.location.hostname));
    initialText = response.data.data.introduction_text;
    initVoice = "en-US-" + response.data.data.voice;
    voice = "en-US-" + response.data.data.voice;
    bgImage = response.data.data.model_background;
    setBackendData(response.data.data);
    document.title = response.data.data.title + (response.data.data.desc ? ' | ' + response.data.data.desc : '');
    document.description = response.data.data.title;
  };

  return <div>{backendData && <Page backendData={backendData} />}</div>;
}

export default App;
